import $ from 'jquery'
import Flash from './flash'
import { __, s__ } from './locale'
import axios from './lib/utils/axios_utils'

export default class ListStar {
  constructor(container = '.project-home-panel') {
    $('body').on('click', '.list-toggle-star', function toggleStarClickCallback () {
      const $this = $(this)
      const $thisText = $(this).find('span')
      const $starCount = $('.' + $this.data('star-class'))

      axios
        .post($this.data('endpoint'))
        .then(({ data }) => {
          const isStarred = $thisText.hasClass('starred')

          if (isStarred) {
            $(this).removeClass('star')
            $thisText.removeClass('starred')
            $thisText.text('Star')
            $starCount.text(data.star_count)
          } else {
            $(this).addClass('star')
            $thisText.addClass('starred')
            $thisText.text('Unstar')
            $starCount.text(data.star_count)
          }
        })
        .catch(() => Flash(__('Star toggle failed. Try again later.')))
    })
  }
}